<template>
    <v-container>
        <v-card>
            <v-card-title class="grey lighten-4">
                <b>REPARTES AGENDADOS</b>
            </v-card-title>
            <v-card-text class="justify-center align-center">
                <v-row>
                    <v-col cols="12" class="justify-center align-center pt-4">
                        <v-data-table multi-sort dense :headers="headers" :items="_listaPedidos" :items-per-page="10" class="elevation-1">
                            <template v-slot:[`item.action`]="{ item }">
                                <div v-if="item.idEstado != 9">
                                    <v-btn color="error" x-small v-if="item.idEstado == 13 || item.idEstado != 8" @click="openDialogOne(item)"
                                        >POR ENTREGAR</v-btn
                                    >
                                    <v-btn color="primary" x-small v-else @click="openDialogTwo(item)"> EN CAMINO </v-btn>
                                </div>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-dialog v-model="dialog" max-width="400" v-if="selectedRepart.atributos">
            <v-card>
                <v-card-title class="text-h5"> Detallles Entrega </v-card-title>
                <v-card-text>
                    <v-form v-model="valid">
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <h4>TRANSPORTISTA</h4>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-autocomplete
                                        item-text="fullName"
                                        item-value="id"
                                        v-model="selectedRepart.atributos.idChofer"
                                        :items="listaChofer"
                                        label="Chofer"
                                        required
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-autocomplete
                                        dense
                                        v-model="selectedRepart.atributos.placaVehiculo "
                                        :items="listTransport"
                                        placeholder="Placas"
                                        label="Placa de vehiculo"
                                        item-text="plate_number"
                                        item-value="id"
                                        outlined
                                    />
                                </v-col>
                                <v-col cols="12" md="12">
                                    <h4>RECEPTOR</h4>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="selectedRepart.atributos.documentoReceptor"
                                        label="Documento Receptor"
                                        @keypress="isNumber"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field v-model="receptor.apellidoPaternoReceptor" label="Apellido Paterno" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field v-model="receptor.apellidoMaternoReceptor" label="Apellido Materno" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field v-model="receptor.nombreReceptor" label="nombreReceptor" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="selectedRepart.numeroComprobante"
                                        placeholder="F001-100"
                                        label="numeroComprobante"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="selectedRepart.numeroGuiaRemision"
                                        placeholder="G001-100"
                                        label="numeroGuiaRemision"
                                        required
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="dialog = false"> Cancelar </v-btn>
                    <v-btn :disabled="!valid" :loading="loading" color="green darken-1" text @click="entregado(selectedRepart)"> Confirmar </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog2" max-width="400">
            <v-card>
                <v-card-title class="text-h5">CONFIRMAR INICIO DEL RECORRIDO </v-card-title>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="dialog2 = false"> Cancelar </v-btn>
                    <v-btn :disabled="!valid" :loading="loading" color="green darken-1" text @click="enCamino(selectedRepart)"> Confirmar </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { mixins } from '@/mixins/mixin.js'
import DefaultForm from '@/utils/defaultForm'
import { format, subDays, addDays } from 'date-fns'
import { exportXLSX } from '@/utils'
import { splitfirsLetter } from '@/utils/genericUtils'

export default {
    mixins: [mixins],

    data() {
        return {
            menu3: false,
            today: format(new Date(), 'yyyy-MM-dd'),
            fechaFiltro: [format(subDays(new Date(), 2), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd')],
            valid: true,
            receptor: {
                apellidoPaternoReceptor: '',
                apellidoMaternoReceptor: '',
                nombreReceptor: ''
            },
            polling: null,
            selectedRepart: {},
            documentoReceptor: '',
            placa: '',
            loading3: false,
            loading: false,
            chofer: null,
            dialog: false,
            dialog2: false,
            headers: [
                { text: 'ACTIONS', value: 'action', sortable: false },
                { text: 'FEC. Entrega', value: 'check_in' },
                { text: 'GUIA REMISION', value: 'numeroGuiaRemision' },
                { text: 'ESTADO', value: 'estado' },
                { text: 'Ticket/OC', value: 'ticketOc' },
                { text: 'Productos', value: '_productos' },
                { text: 'nombreReceptor', value: 'full_name' }
            ]
        }
    },

    watch: {
        async isNumeroDocumento(val) {
            try {
                console.log('valk', val)
                if (val && val.length === 8) {
                    const data = await this.$store.dispatch('clientes/getClienteExternal', {
                        idTipoDocumento: 1,
                        numeroDocumento: val
                    })
                    console.log('data', data)
                    this.receptor.apellidoPaternoReceptor = splitfirsLetter(data.apellido_paterno)
                    this.receptor.apellidoMaternoReceptor = splitfirsLetter(data.apellido_materno)
                    this.receptor.nombreReceptor = splitfirsLetter(data.nombres)
                }
            } catch (error) {
                console.log('error', error)
            }
        }
    },
    async created() {
        await this.$store.dispatch('inventario/estados')
        await this.getListaVenta()
        this.$store.dispatch('users/getLista')
        this.pollData()
    },

    methods: {
        async downloadXlsx() {
            console.log('_listaPedidos', this._listaPedidos)
            this.loading = true
            const items = []
            const headers = ['TICKET/OC', 'FECHA', 'PRODUCTO', 'NOMBRE', 'DIRECCION', 'DNI', 'GUIA', 'PROVINCIA', 'DEPARTAMENTO', 'DISTRITO', 'REFERENCIA']
            for (const x of this._listaPedidos) {
                const detalleUbigeo = x.ubigeo ? await this.$store.dispatch('reparto/detailsUbigeo', x.ubigeo) : null

                items.push({
                    ...x,
                    provincia: detalleUbigeo ? detalleUbigeo[0].provincia : null,
                    departamento: detalleUbigeo ? detalleUbigeo[0].departamento : null,
                    distrito: detalleUbigeo ? detalleUbigeo[0].distrito : null
                })
            }
            const filename = `REPARTO DIARIO ${this.today}`
            exportXLSX(
                {
                    headers,
                    campos: [
                        'ticketOc',
                        'check_in',
                        '_productos',
                        'full_name',
                        'direccion',
                        'numeroDocumento',
                        'numeroGuiaRemision',
                        'provincia',
                        'departamento',
                        'distrito',
                        'referencia'
                    ],
                    arrayData: items
                },
                { filename }
            )
            this.loading = false
        },
        async getListaVenta() {
            await this.$store.dispatch('reparto/getListChofer', { estados: this.listaEstados, fechaFiltro: this.fechaFiltro, date: this.today })
            this.menu3 = false
        },
        openDialogOne(payload) {
            this.dialog2 = false
            this.dialog = true
            this.selectedRepart = payload
        },
        openDialogTwo(payload) {
            this.dialog2 = true
            this.selectedRepart = payload
        },
        pollData() {
            this.polling = setInterval(() => {
                this.getListaVenta()
            }, 300000)
        },
        async entregado(item) {
            console.log('item-----', item)
            console.log('this.receptor ', this.receptor)
            this.loading = true
            const placaVehiculo = (this.listTransport.find((x)=>x.id== item.atributos.placaVehiculo)).plate_number
            const { apellidoPaternoReceptor, apellidoMaternoReceptor, nombreReceptor } = this.receptor
            const fullNameReceptor = `${apellidoPaternoReceptor} ${apellidoMaternoReceptor} ${nombreReceptor}`
            const atributos = { ...item.atributos,placaVehiculo, fullNameReceptor: fullNameReceptor }
            this.$store.commit('mainUI/OPEN_MODAL', {
                state: true,
                text: 'Su Solicitud se está procesando. Verificar su estado en 1 minuto',
                isTimeout: true
            })
            await this.$store.dispatch('reparto/entregadoSodimac', { ...item, atributos })

            this.loading = false
            this.dialog = false
            await this.getListaVenta()
        },
        async enCamino(item) {
            this.loading = true
            console.log('enCamino item ', item)
            //this.loading3 = true
            this.$store.commit('mainUI/OPEN_MODAL', {
                state: true,
                text: 'Su Solicitud se está procesando. Verificar su estado en 1 minuto',
                isTimeout: true
            })
            await this.$store.dispatch('reparto/enCaminoSodimac', item)
            //this.loading3 = false

            this.loading = false
            this.dialog2 = false
            await this.getListaVenta()
        }
    },

    computed: {
        ...mapState('reparto', ['listReparto']),
        ...mapState('proveedor', ['listTransport']),
        ...mapState('inventario', ['listaEstados']),
        ...mapGetters('users', ['listaChofer', 'listaUser']),
        ...mapState('users', ['listaUser']),
        ...mapState('ventas', ['datos']),
        isNumeroDocumento() {
            return this.selectedRepart?.atributos ? this.selectedRepart?.atributos?.documentoReceptor : null
        },
        _listaPedidos() {
            let item = []
            console.log('this.listReparto', this.listReparto)
            this.listReparto.map((x) => {
                const idOrdenCompra = x.idOrdenCompra ? x.idOrdenCompra : ''
                console.log('idOrdenCompra', x.idOrdenCompra)
                console.log('this.listaUser', this.listaUser)
                console.log('x.idColaborador', x.idColaborador)
                if (this.listaUser.length > 0) {
                    const { nombreReceptors } = this.listaUser.find((y) => y.id == x.idColaborador) ?? { nombreReceptors: null }
                    console.log('x.productos ', x.productos)
                    //const productos =JSON.parse( x.prodctos).join(',')

                    item.push({
                        ...x,
                        ticketOc: `${x.id}-${idOrdenCompra}`,
                        atributos: x?.atributos ? x?.atributos : DefaultForm.formAttributes(),
                        nameColaborador: nombreReceptors,
                        _productos: x.productos
                    })
                }
            })
            console.log('items', item)
            return item
        }
    },
    async mounted() {
        await this.$store.dispatch('proveedor/findTransport')
    }
}
</script>
